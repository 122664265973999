@use "@causevest/ui-kit/styles";

.wrapper {
  padding: 35px 0;
  background: styles.$color-bg-blue;
}

.inner {
  gap: 23px;
  margin-top: 45px;
}

.advantages {
  margin-left: 0;
  gap: 23px;

  &__item {
    position: relative;
    display: flex;
    width: calc(50% - 12px);
    border-radius: 5px;
    padding: 20px;
    background: styles.$color-blue-4;
    box-shadow: none;
    font-size: 16px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;

    &._activeBlock {
      box-shadow: 0 7px 20px 0 rgba(4, 14, 27, 0.08);
    }
  }

  &__text {
    margin: auto;
  }

  &__icon {
    display: flex;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(100% - 15px);
    width: 30px;
    height: 30px;
    background: styles.$color-blue-5;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    > img {
      margin: auto;
    }

    &._active {
      opacity: 1;
    }
  }
}

@media (max-width: styles.$md) {
  .imgBlock {
    display: none;
  }

  .advantages {
    &__icon {
      display: none;
    }
  }
}

@media (max-width: styles.$sm) {
  .advantages {
    &__item {
      width: 100%;
    }

    &__text {
      margin: 0;
    }
  }
}
