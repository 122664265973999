@mixin textEllipsis($max-width) {
  max-width: $max-width;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin multilineEllipsis($count, $max-width) {
  max-width: $max-width;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $count;
}
