@use "@causevest/ui-kit/styles";

.avatar {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.title {
  text-align: center;
  color: styles.$color-black-6;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  @include styles.textEllipsis(100%);
}

.id {
  color: styles.$color-grey-7;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 10px;
}