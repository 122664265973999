.sliderWrapper {
  max-width: 652px;
}

.slider {
  padding-right: 2px;
}

.slide {
  width: 295px;
  max-width: 295px;
  margin-bottom: 1px;
}

@media (max-width: 1220px) {
  .sliderWrapper {
    max-width: calc(100vw - 313px);
  }

  .slide {
    width: 250px;
  }
}

@media (max-width: 650px) {
  .sliderWrapper {
    max-width: calc(100vw - 48px);
  }

  .slide {
    width: 240px;
  }
}
