$color-blue: #125cd5;
$color-light-blue: #E7F0FB;
$color-blue-1: #6DA2FB;
$color-blue-2: #07F7FF;
$color-blue-3: #0F69DC;
$color-blue-4: #e8ebf7;
$color-blue-5: #87d7f6;
$color-bg-blue: #F4F5FC;
$color-black: #25292f;
$color-black-7: #5e5873;
$color-black-6: #2B323B;
$color-black-5: #747B86;
$color-black-3: #B3B3B3;
$color-black-2: #CCCCCC;
$color-black-1: #E6E6E6;
$color-light-grey: #EEEEEE;
$color-grey-1: #999999;
$color-grey-2: #80868F;
$color-grey-3: rgba(37, 41, 47, 0.65);
$color-grey-4: #A0A1A2;
$color-grey-5: #ECECEC;
$color-grey-6: #979797;
$color-grey-7: #505B69;
$color-grey-8: #D8D8D8;
$color-white: #FFFFFF;
$color-green: #33A853;
$color-red: #FE4949;

